import React from 'react';
import PropTypes from 'prop-types';
import { ReactPhotoCollage } from 'react-photo-collage';

const Collage = settings => (
  <ReactPhotoCollage style={{
    div: {
      backgroundSize: "contain !important"
    }
  }} {...settings} />
);


Collage.defaultProps = {
  width: '560px',
  height: ['200px'],
  layout: [2,1,2],
  showNumOfRemainingPhotos: false,
};

Collage.propTypes = {
  width: PropTypes.string,
  height: PropTypes.arrayOf(
    PropTypes.string,
  ),
  layout: PropTypes.arrayOf(
    PropTypes.number,
  ),
  showNumOfRemainingPhotos: PropTypes.bool,
  photos: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
};

export default Collage;
